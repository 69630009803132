//AuthContext.js

import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		const storedUser = localStorage.getItem('user');
		if (storedUser) {
			setUser(JSON.parse(storedUser));
		}
		setLoading(false);
	}, []);

	const login = async (username, password) => {

		try {
			const response = await fetch('../UsuarioController', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ user: username, password: password }), // Enviar user y password
			});

			if (response.ok) {
				const data = await response.json();						  
				
				if (data && data.token) {					
					const loggedInUser = { token: data.token, role: data.rol };
					setUser(loggedInUser);
					localStorage.setItem('user', JSON.stringify(loggedInUser));
					navigate('/elbuenadmin');
				} else {
					alert('Token inválido o sin rol asignado');
				}
			} else {
				alert('Error al conectar con la API');				
			}
		} catch (error) {
			console.error('Error al iniciar sesión:', error);
			alert('Ocurrió un error al intentar iniciar sesión. Por favor, intenta nuevamente.');
		}
	};

	const logout = () => {
		setUser(null);
		localStorage.removeItem('user');
		navigate('/login');
	};

	const isAdmin = () => {
		return user && user.role === 'admin';
	};

	if (loading) {
		return <div>Cargando...</div>;
	}

	return (
		<AuthContext.Provider value={{ user, login, logout, isAdmin }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => useContext(AuthContext);

