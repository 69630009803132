// ElbuenAdmin.js
import React from 'react';
import { useAuth } from '../../auth/AuthContext'; // Importar useAuth para acceder a logout

import '../../style/ElbuenAdmin.css';

const ElbuenAdmin = () => {
  const { logout } = useAuth(); // Extraer la función logout del contexto
  return (
    <main className="main-admin">
      <div>
        <h1>Página de Administración</h1>
        <p>Esta página solo es accesible para usuarios con rol de administrador.</p>
        {/* Botón de Cerrar Sesión */}
        <button className='admin-button' onClick={logout}>Cerrar Sesión</button>
      </div>
    </main>
  );
};

export default ElbuenAdmin;