// LoginPage.js
import React, { useState } from 'react';
import { useAuth } from '../auth/AuthContext';

import '../style/LoginPage.css';



const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();



  const handleSubmit = (e) => {
    e.preventDefault();
    login(username, password);    
  };
  

  return (
    <main className="main-login">
      <div className='inicio-sesion'>
        <h2>Iniciar Sesión</h2>
        <input
          type="text"
          placeholder="Usuario"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleSubmit}>Iniciar Sesión</button>
      </div>
    </main>
  );
};

export default LoginPage;